@import "base";
h1, h2, h3, h4 {
	font-weight: 400;
	text-transform: uppercase;
	margin: 0;
	line-height: 1.2;
	letter-spacing: .2px; }
h1 {
	font-size: 28px;
	text-align: center;
	margin-bottom: 15px; }
h2 {
	font-size: 22px; }
a {
	color: $black-color;
	text-decoration: none;
	&:hover {
		color: $accent-color;
		text-decoration: none; } }
input,
textarea {
	border: 1px solid lighten($grey-color, 20%);
	padding: 4px 10px; }

select {
	background: none;
	border: 1px solid lighten($grey-color, 20%);
	background-color: $white-color;
	border-radius: 2px;
	padding: 5px;
	color: $black-color;
	font-size: 13px;
	font-family: inherit; }
.btn {
	padding: 5px 7px;
	font-family: inherit;
	font-size: inherit;
	&-primary {
		color: $accent-color;
		border: 2px solid $accent-color;
		border-radius: 2px;
		text-transform: uppercase;
		font-weight: normal;
		padding: 5px 15px;
		&:hover {
			background-color: lighten($grey-color, 20%);
			border-color: lighten($grey-color, 20%);
			text-decoration: none; } }
	&-default {
		color: $grey-color;
		border: 2px solid $grey-color;
		border-radius: 2px;
		text-transform: uppercase;
		font-weight: normal;
		padding: 5px 25px;
		&:hover {
			background-color: lighten($grey-color, 20%);
			border-color: lighten($grey-color, 20%);
			text-decoration: none; } } }

.df-jcsb {
	display: flex;
	justify-content: space-between; }
.header {
	position: relative;
	z-index: 2;
	&-top {
		background-color: lighten($black-color, 20%);
		padding: 8px 0;
		color: $white-color;
		font-weight: 400;
		font-size: 13px;
		.la {
			font-size: 16px;
			font-weight: 300; }
		.container {
			@extend .df-jcsb;
			align-items: center; }
		a {
			color: $white-color;
			text-decoration: none;
			&:hover {
				color: lighten($grey-color, 20%); } } }
	&-phone-regim {
		display: flex; }
	&-phone {
		a + a {
			margin-left: 7px; } }
	&-regim {
		margin-left: 10px; }
	&-profile {
		a + a {
			margin-left: 7px; } }
	&-middle {
		padding: 15px 0;
		.container {
			@extend .df-jcsb;
			align-items: center; } }
	&-logo {
		width: 200px;
		img {
			@extend .img-responsive; } }
	&-right-top {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-bottom: 10px; }
	&-callback {
		a {
			color: $accent-color;
			font-weight: normal;
			&:hover {
				background-color: lighten($grey-color, 35%);
				border-color: lighten($grey-color, 35%);
				border-radius: 2px; } }
		.la {
			font-size: 18px;
			line-height: .8; } }
	&-search {
		margin-left: 40px;
		&-form {
			border: 2px solid lighten($grey-color, 20%);
			border-radius: 2px;
			display: flex;
			align-items: center;
			input {
				background: none;
				border: none;
				width: 170px;
				padding-top: 4px;
				padding-bottom: 4px; }
			button {
				background: none;
				border: none;
				padding-right: 7px;
				background-color: lighten($grey-color, 35%);
				&:hover {
					color: $accent-color; }
				.la {
					font-size: 20px; } } } }
	&-cart {
		margin-left: 40px;
		a {
			display: inline-block;
			position: relative;
			z-index: 1; }
		.la {
			font-size: 40px;
			color: $accent-color;
			position: relative;
			z-index: 1; }
		.cart-total {
			position: absolute;
			z-index: 2;
			top: -5px;
			left: 50%;
			transform: translateX(-50%);
			font-weight: 600;
			font-size: 15px; } }
	&-bottom {
		display: none;
		background-color: lighten($black-color, 20%);
		padding: 5px 0;
		color: $white-color;
		.container {
			@extend .df-jcsb;
			align-items: center; }
		a {
			color: $white-color; }
		button.toggle-menu {
			border: none;
			background: none;
			color: $white-color;
			padding: 0;
			&:before {
				content: '';
				font-family: lineawesome;
				font-size: 28px;
				line-height: 1; } } } }

.main-menu {
	> ul {
		@extend .list-unstylled;
		display: flex;
		> li {
			position: relative;
			z-index: 2;
			& + li {
				margin-left: 35px; }
			> a {
				font-size: 17px;
				color: $text-color;
				font-weight: normal;
				.la {
					font-size: 13px; }
				&:hover, &.active {
					color: $accent-color; } }
			ul {
				display: none;
				flex-wrap: wrap;
				position: absolute;
				z-index: 3;
				background-color: #fff;
				padding-top: 10px;
				width: 780px;
				@extend .list-unstylled;
				li {
					width: 49%;
					a {
						text-transform: uppercase;
						font-weight: normal;
						text-decoration: none;
						font-size: 15px;
						padding: 3px 10px;
						display: block; } } }
			&:hover {
				ul {
					display: flex; } } } } }
.slideshow {
	margin-bottom: 35px; }

.owl-carousel {
	.owl-nav {
		.owl-prev, .owl-next {
			width: 30px;
			height: 30px;
			display: inline-block;
			border: $accent-color 1px solid;
			transform: rotate(-45deg);
			&.disabled {
				opacity: .3;
				cursor: default; } }
		.owl-prev {
			left: 15px;
			border-right: none;
			border-bottom: none; }
		.owl-next {
			right: 15px;
			border-left: none;
			border-top: none; } }
	.owl-dots {
		position: absolute;
		z-index: 1;
		bottom: 5px;
		left: 50%;
		transform: translateX(-50%);
		.owl-dot {
			background-color: $text-color;
			width: 12px;
			height: 12px;
			display: inline-block;
			margin: 0 5px;
			&.active, &:hover {
				background-color: $accent-color; } } } }
.module-product-block {
	margin-bottom: 20px;
	h2 {
		font-size: 26px;
		font-weight: 300;
		text-align: center;
		position: relative;
		z-index: 0;
		margin-bottom: 25px;
		a {
			border-bottom: 1px solid #000; }
		&:before {
			content: '';
			height: 1px;
			width: 100%;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			background-color: lighten($black-color, 35%);
			left: 0;
			z-index: 1; }
		span {
			background-color: #fff;
			display: inline-block;
			padding: 0 50px;
			position: relative;
			z-index: 2;
			vertical-align: middle; } } }

.product-card {
	width: 250px;
	text-align: center;
	position: relative;
	z-index: 0;
	margin-bottom: 15px;
	.image {
		margin-bottom: 10px;
		a {
			border: 1px solid transparent;
			border-color: lighten($grey-color, 35%);
			display: block;
			&:hover {
				border-color: lighten($grey-color, 15%); }
			img {
				@extend .img-responsive; } } }
	h3.name {
		height: 90px;
		overflow: hidden;
		font-weight: 600;
		font-size: 15px;
		margin-bottom: 10px;
		a {
			color: $text-color;
			&:hover {
				color: $accent-color; } } }
	.description {
		display: none; }
	.stock {
		margin-bottom: 10px;
		font-size: 12px;
		color: $brown-color;
		font-weight: normal; }
	.price {
		margin-bottom: 10px;
		font-size: 20px;
		font-weight: normal;
		&-old {
			font-size: 15px;
			color: lighten($text-color, 30%);
			position: relative;
			z-index: 1;
			font-weight: 300;
			&:after {
				content: '';
				height: 1px;
				background-color: $red-color;
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				z-index: 1;
				transform: translateY(-50%); } }
		&-new {
			color: $red-color; } }
	.buttons-cart {
		display: flex;
		justify-content: space-around;
		align-items: center;
		color: $accent-color;
		font-weight: normal; }

	.button-compare {
		position: absolute;
		z-index: 1;
		top: 4px;
		right: 4px;
		.la {
			font-size: 18px; } } }
.list-product-page {
	.product-card-block {
		.product-card {
			width: auto; } } }
.product-card-list {
	.product-card-block {
		width: 100%;
		max-width: 100%;
		display: block;
		flex: 0 0 100%; }
	.product-card {
		width: 100%;
		display: flex;
		justify-content: space-between;
		.image {
			width: 18%;
			padding: 2px;
			img {
				max-width: 100%; } }
		.name-description-stock {
			width: 46%;
			.name {
				height: auto;
				text-align: left; } }
		.price-buttons {
			width: 30%; }
		.description {
			margin-bottom: 5px;
			font-size: 13px;
			display: block;
			text-align: left; }
		.stock {
			text-align: left; }
		.price {
			margin-bottom: 15px; } } }

.breadcrumb {
	margin-bottom: 15px;
	margin-top: 10px;
	ul {
		@extend .list-unstylled;
		li {
			display: inline-block;
			font-size: 13px;
			& + li {
				margin-left: 4px;
				&:before {
					content: '/';
					margin-right: 4px; } }
			a {
				font-size: 13px; } } } }

#column-left {
	> div {
		margin-bottom: 25px; }
	.module-product-block {
		h2 {
			font-size: 20px;
			span {
				padding: 0 10px; } } }
	.product-card {
		width: auto;
		h3.name {
			height: auto; } } }


.module-block {
	border: 1px solid lighten($grey-color, 30%);
	border-radius: 2px;
	&-header {
		background-color: $accent-color;
		color: $white-color;
		font-size: 18px;
		text-align: center;
		padding: 7px 5px;
		border-radius: 2px 2px 0 0;
		h2 {
			font-size: 18px; } }
	&-content {
		border-radius: 0 0 2px 2px;
		ul {
			@extend .list-unstylled;
			li + li {
				border-top: 1px lighten($grey-color, 30%) solid; }
			a {
				font-size: 15px;
				display: block;
				padding: 5px 10px;
				&:hover, &.active {
					color: $accent-color; } } } } }
.category {
	&-sub-title {
		text-align: center;
		font-weight: normal;
		color: $grey-color;
		font-size: 18px;
		margin-top: -10px; } }


.panel-manage-products {
	margin-bottom: 25px;
	padding: 5px 10px;
	border: 1px solid lighten($accent-color, 20%);
	border-radius: 2px;
	> .row {
		align-items: center; }
	.display-button {
		white-space: nowrap;
		button {
			display: inline-block;
			vertical-align: middle;
			background: none;
			border: none;
			padding: 0;
			.la {
				font-size: 30px;
				color: $accent-color; }
			&.active {
				cursor: default;
				.la {
					color: $grey-color; } } } }
	.sort-limit {
		white-space: nowrap;
		text-align: center;
		label {
 }			// font-size: 16px
		> * {
			display: inline-block;
			vertical-align: bottom;
			select {
				max-width: 150px; } }
		.limit {
			margin-left: 10px; } }
	.compare {
		text-align: right; } }

.pagination-results {
	margin-top: 15px;
	border-top: 1px solid $black-color;
	padding-top: 15px;
	display: flex;
	justify-content: space-between;
	.pagination {
		margin: 0;
		padding: 0;
		list-style-type: none;
		li {
			display: inline-block;
			+ li {
				margin-left: 7px; }
			a {
				background-color: $accent-color;
				color: $white-color;
				padding: 1px 5px;
				border-radius: 2px;
				&:hover {
					background-color: lighten($grey-color, 20%); } } } } }
.search-parameters {
	margin-bottom: 20px;
	h2 {
		margin-top: 15px; }
	.buttons {
		text-align: right; } }

.product-info {
	.main-image {
		margin-bottom: 15px;
		border: 1px lighten($grey-color, 20%) solid;
		border-radius: 2px;
		img {
			max-width: 100%; } }
	.image-additional-item {
		a {
			display: block;
			text-align: center;
			border: 1px solid transparent;
			padding: 5px;
			border-radius: 2px;
			&.active {
				border: 1px solid lighten($grey-color, 20%); } } }
	.product-info-mobile-images {
		.image-additional-item {
			border-radius: 2px;
			border: 1px lighten($grey-color, 20%) solid; } }
	.find-cheaper {
		margin-bottom: 10px;
		a {
			text-decoration: none; } }
	.right-panel {
		position: relative;
		z-index: 0;
		.btn-compare {
			position: absolute;
			right: 15px;
			top: 0;
			.la {
				font-size: 20px; } }
		.price {
			margin-bottom: 10px;
			font-size: 30px;
			color: $accent-color;
			&-old {
				font-size: 18px;
				color: $text-color;
				position: relative;
				&:after {
					content: '';
					height: 1px;
					background-color: $red-color;
					position: absolute;
					top: 50%;
					left: 0;
					right: 0;
					transform: translateY(-50%); } }
			&-new {
				color: $red-color; } } }
	.product-action-one {
		margin-bottom: 15px;
		> div,
		.qty-input > * {
			display: inline-block;
			vertical-align: middle; }
		input {
			width: 50px;
			text-align: center; }
		button.plus, button.minus {
			border: none;
			background: none;
			.la {
				font-size: 20px; } }
		.button-cart {
			margin-left: 10px;
			.btn-primary {
				font-size: 15px;
				.la {
					font-size: 20px;
					line-height: .5; } } } }
	.product-action-two {
		margin-bottom: 15px;
		padding: 5px;
		display: flex;
		button {
			border: none;
			background: none;
			padding: 0; }
		a,button {
			text-transform: uppercase;
			font-size: 16px;
			color: $text-color;
			text-align: center;
			font-weight: normal;
			&:hover {
				color: $accent-color; } }
		.ajax-popup-link {
			margin-right: 15px; }
		.btn-rassrochka {
			position: relative;
			span, i {
				position: absolute; }
			span {
				bottom: 25px;
				right: 0;
				width: 100%;
				background-color: $accent-color;
				color: #fff;
				font-size: 12px;
				padding: 3px 10px;
				display: none;
				text-transform: none;
				&:before {
					position: absolute;
					bottom: -4px;
					right: 2px;
					content: "";
					border-top: 4px solid $accent-color;
					border-left: 4px solid transparent;
					border-right: 4px solid transparent; } }
			i {
				top: -7px {
  			right: 2px; }
				&:hover + span {
					display: block; } } } } }
.product-attribute {
	margin-bottom: 7px;
	table {
		width: 100%;
		tr {
			td {
				width: 49%;
				padding: 2.5px 0;
				&:last-of-type {
					text-align: center;
					font-size: 13px; } } } } }
.tags {
	>* {
		display: inline-block;
		vertical-align: top; }
	ul {
		li {
			display: inline-block;
			&:after {
				content: ','; }
			&:last-of-type {
				&:after {
					content: ''; } }
			a {
				color: $accent-color; } } } }
.product-description {
	border-top: 1px $text-color solid;
	margin-top: 18px;
	padding-top: 15px;
	&-content {
		h1, h2, h3 {
			color: $accent-color; } } }
iframe {
	width: 100%!important; }

.product-download {
	margin-bottom: 15px; }


.product-review {
	h3 {
		margin: 15px 0 10px; }
	.rating.form-group label {
		display: inline-block;
		margin-bottom: 0;
		margin-right: 7px; } }
.reviews-list-block {
	margin-bottom: 20px;
	.reviews-list {
		&-item {
			margin-bottom: 20px;
			.top {
				display: table;
				width: 100%;
				margin-bottom: 10px;
				> div {
					display: table-cell;
					vertical-align: middle;
					width: 50%; }
				.author-date {
					text-align: right;
					padding-right: 10px;
					span {
						font-weight: 500; } }
				.rating {
					color: $yellow-color; } }
			.message-wrap {
				text-align: center;
				.message {
					font-style: italic;
					display: inline-block;
					padding: 15px 20px;
					position: relative;
					&:before,
					&:after, {
						position: absolute;
						font-family: lineawesome;
						color: #666;
						font-size: 13px; }
					&:before {
						content: '';
						top: 0;
						left: 0; }
					&:after {
						content: '';
						bottom: 0;
						right: 0; } } } } } }
.form-group {
	&.required {
		label {
			&:after {
				content: '*';
				color: $red-color;
				margin-left: 3px; } } }
	label {
		display: block;
		margin-bottom: 3px; }
	input[type="text"],
	input[type="tel"],
	input[type="email"],
	input[type="password"],
	textarea {
		display: block;
		width: 100%; }
	& + &,
	& + .buttons, {
		margin-top: 10px; }
	&.captcha-field {
		input {
			margin-bottom: 7px; } } }
.compare-widget {
	a {
		position: fixed;
		text-decoration: none;
		right: 0;
		top: 25%;
		background-color: #fff;
		opacity: .6;
		box-shadow: 0 1px 3px #cecece;
		border: 1px $accent-color solid;
		border-right: none;
		padding: 5px 7px;
		z-index: 1;
		text-align: center;
		.fa, span {
			display: block;
			text-align: center; }
		.fa {
			margin-bottom: 7px;
			font-size: 20px; }
		&:hover {
			opacity: 1; } } }

.simplecheckout-cart {
	margin-bottom: 20px;
	&-products {
		.item {
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			padding-top: 15px;
			> * {
				padding: 5px; }
			.product {
				width: 50%;
				.image {
					float: left;
					border: 1px solid lighten($grey-color, 20%); }
				.meta {
					margin-left: 120px;
					.name {
						margin-bottom: 5px;
						a {
							font-size: 15px; } }
					.model {
						margin-bottom: 10px;
						font-size: 12px; }
					.price {
						color: $accent-color;
						font-size: 16px;
						font-family: $header-font;
						span {
							color: lighten($text-color, 50%);
							font-size: 14px; } } } }
			.quantity,
			.total,
			.remove {
				text-align: center; }
			.quantity {
				width: 30%;
				white-space: nowrap;
				> * {
					display: inline-block;
					vertical-align: middle; }
				button {
					background: none;
					border: none;
					.la {
						font-size: 20px; } }
				input {
					width: 40px;
					text-align: center; } }
			.total {
				width: 15%;
				font-family: $header-font;
				font-size: 20px;
				white-space: nowrap;
				span {
					color: lighten($text-color, 50%);
					font-size: 15px; } }
			.remove {
				width: 5%;
				button {
					background: none;
					border: none;
					.la {
						font-size: 18px;
						color: $red-color; } } } } }
	&-modules {
		border-top: 1px lighten($text-color, 30%) solid;
		padding-top: 25px;
		display: table;
		width: 100%;
		>* {
			display: table-cell;
			padding: 5px;
			vertical-align: middle;
			&:first-child {
				width: 85.5%;
				text-align: right; } }
		.form-control {
			width: auto; } }
	&-totals {
		display: table;
		width: 100%;
		font-size: 15px;
		> div {
			display: table-row;
			> span {
				display: table-cell;
				padding: 5px;
				&:first-child {
					text-align: right;
					width: 85.5%; }
				&:last-of-type {
					white-space: nowrap;
					font-size: 15px; } } }
		#total_total {
			font-size: 14px;
			span {
				&:last-of-type {
					color: $accent-color;
					font-size: 17px;
					font-weight: 600; } } } } }
.simplecheckout-block {
	h2 {
		margin-bottom: 15px;
		font-size: 20px;
		font-weight: 300;
		text-align: center;
		position: relative;
		z-index: 0;
		&:before {
			content: '';
			height: 1px;
			width: 100%;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			background-color: lighten($black-color, 35%);
			left: 0;
			z-index: 1; }
		span {
			background-color: #fff;
			display: inline-block;
			padding: 0 15px;
			position: relative;
			z-index: 2;
			vertical-align: middle; } }

	.radio {
		margin-bottom: 12px;
		input {
			margin-right: 5px; } } }

#simplecheckout_shipping_address,
#simplecheckout_comment {
	margin-top: 15px; }
#agreement_checkbox {
	margin-bottom: 10px; }

#simplecheckout_shipping {
	b {
		font-family: $header-font;
		font-weight: 600; }
	p {
		margin-bottom: 3px; } }

.scroll-to-top {
	position: fixed;
	background: none;
	background-color: lighten($grey-color, 30%);
	border: none;
	bottom: 10px;
	left: 15px;
	display: none;
	z-index: 1;
	border-radius: 2px;
	color: $text-color;
	.la {
		font-size: 30px;
		line-height: 1.2; } }


#main-content-footer {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between; }

.table {
	&.table-bordered {
		td {
			padding: 5px;
			background-color: #fff; }
		tr:nth-child(even) {
			td {
				background-color: lighten($text-color, 75%); } }
		thead {
			td {
				font-weight: 500; } } } }
.contact-page-contacts {
	margin-bottom: 20px;
	.line {
		.icon {
			float: left;
			.la {
				font-size: 23px;
				width: 25px;
				line-height: 1;
				text-align: center; }
			+ .content {
				margin-left: 30px; } }
		+ .line {
			margin-top: 15px; } } }
.contact-page-form {
	h3 {
		margin-bottom: 10px; }
	.buttons {
		text-align: right; } }
.flycart {
	&-heading {
		h3 {
			margin-bottom: 15px; }
		p {
			margin: 0;
			margin-bottom: 15px; }
		a {
			text-decoration: underline; } }

	.products-item {
		border-top: 1px lighten($grey-color, 30%) solid;
		padding-top: 10px;
		margin-bottom: 10px;
		.name {
			margin-bottom: 2px;
			text-align: left;
			a {
				text-transform: uppercase; } }
		.stock,
		.quantity {
			color: $black-color; }
		.stock {
			text-align: left; }
		.total {
			white-space: nowrap; } }
	&-buttons {
		border-top: 1px lighten($grey-color, 30%) solid;
		padding-top: 15px;
		display: flex;
		justify-content: space-between; } }
.fastorder-form {
	.image {
		img {
			@extend .img-responsive; } } }
.footer {
	border-top: 1px solid lighten($black-color, 35%);
	padding-top: 15px;
	margin-top: 15px;
	h4 {
		font-size: 15px;
		font-weight: 600;
		margin-bottom: 7px; }
	p {
		margin: 0;
		padding: 0; }
	.la {
		color: $accent-color; }
	&-bottom {
		margin-top: 15px;
		background-color: lighten($black-color, 15%);
		padding: 8px 0;
		color: $white-color;
		font-weight: 400;
		font-size: 13px;
		.container {
			@extend .df-jcsb;
			align-items: center; } } }


@import "media";
