@import "fonts";
@import "libs";
@import "vars";

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	&:before, &:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box; } }

*::-webkit-input-placeholder {
	color: #bdbdbd;
	opacity: 1; }

*:-moz-placeholder {
	color: #bdbdbd;
	opacity: 1; }

*::-moz-placeholder {
	color: #bdbdbd;
	opacity: 1; }

*:-ms-input-placeholder {
	color: #bdbdbd;
	opacity: 1; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: #bdbdbd; }
body input:required:valid,
body textarea:required:valid {
	color: #bdbdbd; }

html, body {
	height: 100%;
	background-color: #fff;
	margin: 0;
	padding: 0; }

body {
	min-width: 320px;
	font-weight: 300;
	position: relative;
	line-height: 1.5;
	font-family: $text-font;
	font-size: 14px;
	color: $text-color;
	overflow-x: hidden;
	opacity: 1; }
button,
a {
	outline: none;
	border-radius: 0;
	cursor: pointer;
	transition: .2s all linear; }

input,
textarea {}
fieldset {
	border: none;
	padding: 0;
	margin: 0; }
select {
	outline: none; }
input,
textarea {
	outline: none;
	background: none;
	border: none;
	font-family: inherit;
	font-size: inherit;
	&:focus {
		outline: none; } }
.btn {
	text-decoration: none;
	background: none;
	border: none;
	outline: none;
	display: inline-block;
	border-radius: 0;
	cursor: pointer;
	line-height: 1; }
.text-left {
	text-align: left; }
.text-right {
	text-align: right; }

.error-message, .text-danger {
	color: $red-color;
	font-size: 13px;
	margin: 5px 0; }
.alert {
	margin-bottom: 20px;
	padding: 15px 35px 15px 15px;
	position: relative;
	background-color: #fff;
	button {
		background: none;
		border: none;
		position: absolute;
		right: 5px;
		top: 5px; }
	&-danger {
		border: 1px solid $red-color; }
	&-success {
		border: 1px solid $brown-color; } }
.list-unstylled {
	list-style: none;
	margin: 0;
	padding: 0; }
.img-responsive {
	max-width: 100%;
	height: auto; }
.owl-carousel {
	background: none;
	.owl-wrapper-outer {
		background: none;
		border: none;
		border-radius: 0;
		box-shadow: none;
		text-align: center; }
	.owl-item {
		text-align: center;
		.product-card {
			display: inline-block; } }
	.owl-nav, .owl-prev, .owl-next {
		position: absolute; }
	.owl-nav {
		width: 100%;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%); } }

.nowrap {
	white-space: nowrap; }

.compare-widget {
	a {
		position: fixed;
		text-decoration: none; } }

iframe {
	width: 100%!important; }


div.table-responsive {
	overflow-x: auto; }
.table {
	width: 100%;
	margin-bottom: 20px; }

.mfp-ajax-holder .mfp-content {
	max-width: 600px;
	width: 100%;
	background-color: #fff; }
.wrapper-popup-form {
	> div {
		padding: 15px;
		text-align: center; }
	h2 {
		margin-bottom: 15px; }
	.buttons {
		margin-top: 15px; } }

.scroll-to-top {
	position: fixed;
	background: none;
	background-color: $text-color;
	border: none; }



