@import "vars";

@media only screen and (max-width: 1200px) {
	.main-menu {
		> ul {
			> li {
				& + li {
					margin-left: 15px; }
				> a {
					font-size: 15px; }
				ul {
					li {
						a {
							font-size: 15px; } } } } } }
	.simplecheckout-cart-products {
	  .item {
	    position: relative;
	    z-index: 0;
	    .remove {
	      position: absolute;
	      z-index: 1;
	      width: auto;
	      top: 5px;
	      right: 0; } } } }

@media only screen and (max-width: 992px) {
	.header {
		&-logo {
			width: 120px; }
		&-right-top {
			margin-bottom: 0; }
		&-search {
			&-form {
				input {
					padding-top: 2px;
					padding-bottom: 2px; } } }
		&-cart {
			.la {
				font-size: 35px; } } }
	.main-menu {
		> ul {
			> li {
				& + li {
					margin-left: 10px; }
				> a {
					font-size: 14px; }
				ul {
					li {
						a {
							font-size: 14px; } } } } } }
	.module-product-block {
		.product-card {
			width: 220px; }
		.buttons-cart {
			font-size: 13px;
			.btn-primary {
				padding-left: 15px;
				padding-right: 15px; } } }
	.simplecheckout-cart-products {
	  .item {
	    .total {
	    	text-align: left;
	    	span {
	    		display: block; } } } }
	.panel-manage-products {
		.display-button,
		.compare {
			display: none; } }

	#column-left {
		> div {
			margin-bottom: 15px; }
		.module-block {
			&-content {
				ul {
					a {
						font-size: 14px; } } } }
		.module-product-block {
			h2 {
				font-size: 17px;
				margin-bottom: 10px;
				span {
					padding: 0 5px; } }
			.product-card {
				h3.name {
					font-size: 14px;
					margin-bottom: 5px; }
				.stock {
					margin-bottom: 5px; }
				.price {
					font-size: 15px; }
				.buttons-cart {
					display: block;
					.btn-primary {
						margin-bottom: 5px; } } } } }
	.product-info {
		.price {
			&-new {
				display: block;
				line-height: 1; } }
		.product-action-one {
			display: flex;
			justify-content: space-between;
			align-items: center;
			input {
				padding: 2px 4px; }
			button.plus, button.minus {
				.la {
					font-size: 18px; } }
			.button-cart {
				margin-left: 5px;
				.btn-primary {
					font-size: 14px;
					padding-right: 9px;
					padding-left: 9px; } } }
		.product-action-two {
			justify-content: space-between;
			align-items: center;
			.ajax-popup-link {
				margin-right: 0;
				white-space: nowrap; }
			a, button {
				font-size: 14px; } } } }


@media only screen and (max-width: 767px) {
	.header {
		margin-bottom: 15px;
		&-top {
			padding: 5px 0;
			font-size: 13px;
			font-weight: 300;
			.la {
				font-size: 14px; } }
		&-phone-regim {
			> * {
				position: relative;
				z-index: 0;
				padding-left: 20px;
				font-size: 12px;
				line-height: 1.2;
				.la {
					position: absolute;
					z-index: 0;
					top: 50%;
					transform: translateY(-50%);
					left: 0; } } }
		&-phone {
			a {
				display: block; }
			a + a {
				margin-left: 0px; } }
		&-regim {
			span {
				display: block; } }
		&-profile {}

		&-middle {
			padding: 10px 0;
			.container {
				align-items: flex-start; } }
		&-logo {
			width: 110px; }
		&-right-top {
			flex-wrap: wrap; }
		&-callback {
			order: 1;
			a {
				font-size: 13px;
				.la {
					font-size: 14px; } } }
		&-search {
			display: none; }
		&-cart {
			margin-left: 5px;
			order: 2;
			.la {
				font-size: 30px; }
			.cart-total {
				font-weight: 400;
				font-size: 13px; } }
		&-bottom {
			display: block;
			.header-search {
				display: block;
				margin-left: 0px;
				&-form {
					border-width: 1px;
					input {
						width: 150px;
						padding-top: 1px;
						padding-bottom: 1px;
						font-size: 13px; }
					button {
						padding-right: 6px;
						color: lighten($black-color, 20%);
						.la {
							font-size: 16px; } } } } } }

	.main-menu {
		display: none;
		position: absolute;
		z-index: 1;
		top: 100%;
		left: 0;
		right: 0;
		background-color: lighten($black-color, 20%);
		> ul {
			padding: 10px 15px 10px 15px;
			display: block;
			> li {
				position: relative;
				z-index: 2;
				& + li {
					margin-left: 0px; }
				> a {
					font-size: 14px;
					color: $white-color;
					.la {
						font-size: 13px; }
					&:hover, &.active {
						color: $accent-color; } }
				ul {
					display: none;
					position: static;
					background-color: #fff;
					padding-top: 10px;
					width: auto;
					li {
						width: auto;
						a {
							text-transform: uppercase;
							font-weight: normal;
							text-decoration: none;
							font-size: 15px;
							padding: 3px 10px;
							display: block; } } }
				&:hover {
					ul {
						display: none; } } } } }
	.slideshow-wrap {
    	display: none; }


	.module-product-block {
		margin-bottom: 10px;
		h2 {
			font-size: 20px;
			margin-bottom: 15px;
			span {
				padding: 0 10px; } }
		.product-card {
			h3.name {
				height: 77px;
				font-size: 14px;
				margin-bottom: 7px; }
			.stock {
				margin-bottom: 3px; }
			.price {
				margin-bottom: 5px;
				font-size: 16px;
				&-old {
					font-size: 14px; } } } }

	.footer {
		h4 {
			margin: 0;
			padding: 0;
			line-height: 1;
			margin-bottom: 3px; }
		p {
			margin: 0;
			padding: 0;
			margin-bottom: 4px; }
		&-top {
			> .container > .row > div {
				margin-bottom: 10px; } }
		&-bottom {
			padding-top: 10px;
			> .container {
				display: block; }
			.powered {
				margin-bottom: 5px;
				text-align: center; }
			.icons {
				text-align: center; } } }

	.simplecheckout-cart-products {
	  .item {
	    display: block;
	    .product {
	      width: 100%;
	      display: inline-block;
	      vertical-align: middle; }
	    .quantity,
	    .total {
	      width: auto;
	      margin-left: 110px;
	      text-align: left;
	      display: inline-block;
	      vertical-align: middle;
	      span {
	        display: inline; } }
	    .remove {
	    	top: auto;
	    	bottom: 45px; } } }

	.simplecheckout-cart-modules {
	  .simplecheckout-cart-total {
	    input {
	      width: 115px; } } }


	.breadcrumb {
		margin-bottom: 10px; }
	h1 {
		font-size: 24px; }
	.category-sub-title {
		font-size: 15px;
		line-height: 1.1;
		margin-bottom: 10px; }
	.category-description {
		display: none; }
	.panel-manage-products {
		.display-button,
		.compare {
			display: none; } }
	.pagination-results {
		text-align: center;
		display: block;
		.results {
			margin-top: 10px; } }
	.product-info {
    	.product-info-mobile-images {
    		margin-bottom: 10px; }
    	.product-action-two {
    		border-top: 1px lighten($grey-color, 30%) solid;
    		border-bottom: 1px lighten($grey-color, 30%) solid;
    		margin-bottom: 10px; }
    	.find-cheaper .btn-default {
    		padding-right: 15px;
    		padding-left: 15px; } }
	.contact-page-form {
		.buttons {
			text-align: left; } }
	.wrapper-popup-form {
		.buttons {
			button {
				margin-bottom: 7px; } } }
	.flycart-buttons {
		display: block;
		> div + div {
			margin-top: 7px; } }
	.scroll-to-top {
    	left: 5px; }
	.simplecheckout-block-content select {
		max-width: 270px; } }

@media only screen and (max-width: 575px) {
	.header {
		&-phone-regim {
			width: 100%;
			justify-content: space-between; }
		&-profile {
			position: absolute;
			z-index: 1;
			right: 15px;
			bottom: 47px;
			a {
				color: $black-color; } } }

	.simplecheckout-cart-modules>:first-child {
		text-align: center; }

	.product-card {
		h3.name {
			height: auto; } }
	.panel-manage-products {
		.display-button,
		.sort-limit .limit,
		.compare {
			display: none; } }
	.list-product-page {
		.product-card-block {
			text-align: center;
			.product-card {
				display: inline-block;
				margin-bottom: 25px; } } } }



